
import { Container, Typography } from "@mui/material"


export default function Footer(){

    return (
      
         <Container sx={{
            width: '100%',
          
         }}>
            

            <Typography component="div" variant='disclaimerText'
            sx={{
                textAlign: 'center'
            }} >
                Paid for by the Paul Wymer for City Council Campaign
            </Typography>

     </Container>
    )
}

