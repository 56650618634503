import * as React from 'react';
import { Box, Grid,  Link} from '@mui/material';

export default function Header() { 
    return (
        
    <Box textTransform='uppercase'>
        <Grid container>
            <Grid item xs={6} sm={5} component={'span'}>
            
            </Grid>
            <Grid item xs={6} sm={7} component={'span'} sx={{ marginTop: '.4rem'}}>
               <img src='./common/d4logo.png' alt="City Counsilor District 4" className='d4logo'/>
            </Grid>
            <Grid item xs={12}  >
                <Link href='/'>
                <img src='./common/pwlogo.png' alt="Paul Wymer" className='pwlogo'/>
               </Link>
            </Grid>
        </Grid>
    </Box>
)
}