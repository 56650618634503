import * as React from 'react';
import { Typography,  Container} from '@mui/material';
import paulImage from '../images/paul_thanks.png'


import useMediaQuery from '@mui/material/useMediaQuery';

export default function Home() { 

const mdScreen = useMediaQuery('(min-width:600px)');


return ( 
    <>
         <Typography component="div" style={{ margin: 'auto', marginLeft: '4rem', position: 'relative', fontSize:'1.7em', fontWeight: 'bold', textAlign: 'center'}} >
                    Thank you to Rio Rancho's District 4!
        </Typography>
        {mdScreen ? 
        <>

        <Container style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }} >
            <img src={paulImage} alt="Paul at City Hall" className='homeImage' style={{ width: '50vh',height:'auto', margin: '1rem', borderRadius: '3rem'}} />
        </Container>
        </>
           : // Not mdScreen section
           <>
           <Container>
               <img src={paulImage} alt="Paul Says Thank you!" style={{ width: '100%', padding: '1rem', borderRadius: '2rem' }} />
            </Container>
           </>
         }
        <Typography component="div" style={{marginRight: 'auto', marginTop: '1rem', marginBottom: '1rem', position: 'relative', fontSize:'1.5rem', fontWeight: 'bold',textAlign: 'center'}} >
                For choosing me to continue working for you!
        </Typography>


  </>
);
}