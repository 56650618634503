import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import theme from './theme'
import Home from './pages/Home';
import { Container, CssBaseline } from '@mui/material';

import Header from './pages/Header';
import Footer from './pages/Footer'

export default function App(){
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          {/* <Route path='/About' element={<About />} />
          <Route path='/Accomplishments' element={<Accomplishments />} />
          <Route path='/AccomplishItem' element={<AccomplishItem />} />
          <Route path='/Endorsements' element={<Endorsements />} />
          <Route path='/EndorsementItem' element={<EndorsementItem />} /> */}
        </Routes>
      </BrowserRouter>

  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xxl">
        <Header />
          
          <App />
        <Footer />
      </Container>
    </ThemeProvider>
  </React.StrictMode>
  
);
