import { createTheme } from '@mui/material/styles';

// --uranian-blue: #a9cef4ff;
// --outer-space: #36494eff;
// --black: #000000ff;
// --air-superiority-blue: #7ea0b7ff;
// --paynes-gray: #597081ff;

const primary = '#36494eff';
const secondary = '#a9cef4ff';
const black = '#000000ff';
const white = '#ffffffff';
const backgroundColor = '#4f7492';

let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1500,
            xxl: 1800
        }
    },
});

theme = createTheme(theme,{
    palette: {
      primary: {
        main: primary,
        black: black,
        white: white
      },
      secondary: {
        main: secondary,
      },
      background: {
        default: backgroundColor
      },
    },
    
    typography: {
        // Default font
        fontFamily: "'Exo 2', sans-serif;",
        fontSize: '.5rem',

        reelectText: {
            fontFamily: "'Times New Roman', sans-serif;",
            fontSize: '1.2rem',
            marginLeft: '2rem',
            padding: '0',
            margin: '0',
            color:  'white',
            fontWeight: '900',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1.4rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1.6rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1.9rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1.9rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '2rem',
                fontWeight: '600',
            },

        },
       
        ctaText: {
            fontSize: '.8rem',
            color: black,
            fontWeight: '300',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1.3rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1.4rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '1.5rem',
                fontWeight: '600',
            },
        },
        stdText: {
            // Mobile Version
            fontSize: '.9rem',
            color: black,
            fontWeight: '300',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1rem',
                fontWeight: '300',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1rem',
                fontWeight: '300',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1rem',
                fontWeight: '300',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1rem',
                fontWeight: '300',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '1rem',
                fontWeight: '300',
            },
            },
            
        tileText: {
            fontSize: '1rem',
            color: black,
            fontWeight: '700',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '1rem',
                fontWeight: '600',
            },
        },
                
        // tnyText: {
        //     fontSize: '.5rem',
        //     color: black,
        //     fontWeight: '900',

        // },
        contactText: {
            fontSize: '.6rem',
            color: black,
            fontWeight: '900',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '1rem',
            },
        },
        disclaimerText: {
            fontSize: '.6rem',
            color: black,
            fontWeight: '900',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1rem',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '1rem',
            },

        },
        
                
        aboutTitleText: { 
            // Mobile Sizes
            textTransform:'uppercase',
            fontFamily: "'Exo 2', sans-serif;", 
            fontWeight: '900', 
            fontSize: '1.2rem', 
            paddingBottom: '1rem',
            
            [theme.breakpoints.up('sm')]:{
                fontSize: '1.5rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('md')]:{
                fontSize: '1.6rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('lg')]:{
                fontSize: '1.7rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xl')]:{
                fontSize: '1.8rem',
                fontWeight: '600',
            },
            [theme.breakpoints.up('xxl')]:{
                fontSize: '1.9rem',
                fontWeight: '600',
            },
         }
         
    
    },
    components: {
        MuiButton:{
            variants:[
                {
                    props: { variant: 'donateButton' },
                    style: {
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        borderRadius: '.4rem',
                        padding:'.3rem',
                        marginTop:'1rem',
                        backgroundColor: '#280CB8',
                        color: 'white',
                        
                        [theme.breakpoints.up('sm')]:{
                            fontSize: '.7rem',
                            padding: '.2rem'
                        },
                        [theme.breakpoints.up('md')]:{
                            fontSize: '.8rem',
                            padding: '.4rem'
                        },
                        [theme.breakpoints.up('lg')]:{
                            fontSize: '1rem',
                        },
                        [theme.breakpoints.up('xl')]:{
                            fontSize: '1rem',
                        },
                        [theme.breakpoints.up('xxl')]:{
                            fontSize: '1rem',
                        },
                    },
                },
                {
                        props: { variant: 'menuItem' },
                        style: {
                            
                                // Mobile Sizes
                                fontWeight: '300',
                                fontSize: '.8rem',
                                textTransform: 'uppercase',
                                backgroundColor: '#a60a0c',
                                borderRadius: '.6rem',
                                padding: '.2rem .6rem .2rem .6rem',
                                margin: '.2rem',
                                marginTop:'1rem',
                                color: 'white',
                                underline: 'none',
                                [theme.breakpoints.up('sm')]:{
                                    fontSize: '.8rem',
                                },
                                [theme.breakpoints.up('md')]:{
                                    fontSize: '.7rem',
                                    
                                },
                                [theme.breakpoints.up('lg')]:{
                                    fontSize: '1rem',
                                    
                                },
                                [theme.breakpoints.up('xl')]:{
                                    fontSize: '1rem',
                                    
                                },
                                [theme.breakpoints.up('xxl')]:{
                                    fontSize: '1rem',
                                    
                                },
                        }
                }]
                            
            }
        },
        
    
    
  });

 

  export default theme;